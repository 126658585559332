// layout.scss

.page {
}

.main {
	overflow: hidden;
	position: relative;
}

.layout {
	@include clearfix();
	position: relative;
	margin: 0 $ggw;
	max-width: $max-width;

	// background-color: $brand-white;

	@media (min-width: $break-point-f) {
		margin: 0 auto;
	}

	&.layout--a {
		margin: 0;

		@media #{$BPD} {
			margin: 0 $ggw;
		}

		@media #{$BPF} {
			margin: 0 auto;
		}
	}
}

.masonry {
	overflow: hidden;
	clear: both;
}

.region {

	@media (min-width: $break-point-d) {
		position: relative;
		float: left;
	}

	.rtl & {

		@media (min-width: $break-point-d) {
			float: right;
		}
	}
}

.region__inner {
	position: relative;
}

.block {
	float: left;
	width: 100%;
	max-width: 100%;
	margin-bottom: $vr*3;

	.rtl & {
		float: right;
	}

	img {
		max-width: 100%;
		height: auto;
		vertical-align: top;
	}

	&.block--last {

		& + .block {
			clear: both;
		}
	}

	.js .masonry & {
		margin-right: 0!important;

		@media (min-width: $break-point-d) {
			margin-bottom: 0!important;
		}
	}
}

.block-sizer {
	float: left;
	max-width: 100%;
}

.gutter-sizer {
	float: left;
	max-width: 100%;
}

.block-last-clear {
	clear: both;
}

@mixin region-blocks($region-outer-width:0, $region-inner-width:0) {
	.block--single {
		@media (min-width: $break-point-d) {
			@include fluid-width($gcw*1, $region-inner-width);
		}
	}

	.block--size-a {
		@media (min-width: $break-point-d) {
			// 100%
			 @include fluid-width(($gcw*12) + ($ggw*11), $region-inner-width);
		}
	}

	.block--size-b {
		@media (min-width: $break-point-d) {
			// 66%
			@include fluid-width(($grid-column-width*8) + ($grid-gutter-width*7),  $region-inner-width);
		}
	}

	.block--size-c {
		@media (min-width: $break-point-d) {
			// 33%
			@include fluid-width(($gcw*4) + ($ggw*3), $region-inner-width);
		}
	}
}

.region--a {

	// Grid layout above $break-point-d only
	@media (min-width: $break-point-d) {
		position: relative;
		float: left;
		@include fluid-width($region-a-width, $max-width);

		.region__inner {
			position: relative;
			@include fluid-width($region-a-inner-width, $region-a-width);
            height: 100%;
		}

		.block {
			@include fluid-margin-right($ggw, $region-a-inner-width);

			.rtl & {
				margin-right: 0;
				@include fluid-margin-left($ggw, $region-a-inner-width);
			}
		}

		.gutter-sizer {
			@include fluid-width($ggw, $region-a-inner-width);
		}
	}

	@include region-blocks($region-a-width, $region-a-inner-width);
}

.region--b {

	// Grid layout above $break-point-d only
	@media (min-width: $break-point-d) {
		position: relative;
		float: left;
		@include fluid-width($region-b-width, $max-width);

		.region__inner{
			position: relative;
			@include fluid-width($region-b-inner-width, $region-b-width);
            height: 100%;
		}

		.block {
			@include fluid-margin-right($ggw, $region-b-inner-width);

			.rtl & {
				margin-right: 0;
				@include fluid-margin-left($ggw, $region-b-inner-width);
			}
		}

		.gutter-sizer {
			@include fluid-width($ggw, $region-b-inner-width);
		}
	}

	@include region-blocks($region-b-width, $region-b-inner-width);
}

.region--c {

	// Grid layout above $break-point-d only
	@media (min-width: $break-point-d) {
		position: relative;
		float: right;
		margin: 0;
		@include fluid-width($region-c-width, $max-width);

		.region__inner{
			position: relative;
			@include fluid-width($region-c-inner-width, $region-c-width);
            height: 100%;
		}

		.block {
			@include fluid-margin-right($ggw, $region-c-inner-width);

			.rtl & {
				margin-right: 0;
				@include fluid-margin-left($ggw, $region-c-inner-width);
			}
		}

		.gutter-sizer {
			@include fluid-width($ggw, $region-c-inner-width);
		}
	}

	@include region-blocks($region-c-width, $region-c-inner-width);
}

// show the grid
.visible-grid-in {

	.block {
		background: rgba(202, 214, 25, 0.5);
	}
}

.gridblock {
	background: $brand-color-a;
	color: #FFF;
	padding: $vr*2 15px;
	text-align: center;

	p {
		margin: 0;
	}
}

.gridblock__title {
	color: #FFF;
	margin-top: 0;
}
