// Buttons
//
// Button styles can be applied to anything with the `.btn` class applied.
// However, typically you'll want to apply these to only `<a>` and `<button>` elements for the best rendering.
//
// Styleguide 4
//

$btn-border-width: 2px;

$btn-padding-y-small: $vr;
$btn-padding-x-small: 15px;
$btn-line-height-small: $vr*4 - $btn-border-width*2;
$btn-total-height-small: $btn-padding-y-small*2 + $btn-line-height-small;

$btn-padding-y-default: $vr*4;
$btn-padding-x-default: 36px;
$btn-line-height-default: $vr*4 - $btn-border-width*2;
$btn-total-height-default: $btn-padding-y-default*2 + $btn-line-height-default;

$btn-padding-y-large: $vr*2;
$btn-padding-x-large: 30px;
$btn-line-height-large: $vr*7 - $btn-border-width*2;
$btn-total-height-large: $btn-padding-y-large*2 + $btn-line-height-large;

// buttons at smallscreen are reduced by one size (to a minimum of *-small)
// e.g .btn below 768px uses *-small units, .btn-large uses *-default... but .btn-small still uses *-small

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

button::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.btn {
	@include underline-reset();

	background-color: #CCC;
	background-image: none;
	border: $btn-border-width solid #CCC;
	box-shadow: none;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: $font-family-base;
	font-size: 20px;
	font-weight: $font-weight-bold;
	line-height: $btn-line-height-small;
	margin-bottom: 0; // For input.btn
	min-height: $btn-total-height-small;
	padding: $vr*3 25px;
	// padding: $btn-padding-y-small $btn-padding-x-small;
	text-align: center;
	text-decoration: none;
	transition: background 250ms, border-color 250ms;
	vertical-align: top;
	white-space: nowrap;
	-webkit-font-smoothing: antialiased; // get rid of faux bolding

	@media (min-width: $break-point-d) {
		line-height: $btn-line-height-default;
		min-height: $btn-total-height-default;
		padding: $btn-padding-y-default $btn-padding-x-default;
	}

	&:hover,
	&:focus {
		background: darken(#CCC, 10%);
		border-color: darken(#CCC, 10%);
		box-shadow: none;
		text-decoration: none;
	}

	&:active,
	&.active {
		box-shadow: none;
		outline: 0;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		opacity: 0.65;

		box-shadow: none;
		cursor: default;
		pointer-events: none; // Future-proof disabling of clicks
	}
}

// Sizing
//
// .btn-large  - Large button.
// .btn-small  - Small button.
//

.btn--small {
	font-size: 12px;
	line-height: $btn-line-height-small;
	min-height: $btn-total-height-small;
	padding: $btn-padding-y-small $btn-padding-x-small;

	@media (min-width: $break-point-d) {
		font-size: 12px;
		line-height: $btn-line-height-small;
		min-height: $btn-total-height-small;
		padding: $btn-padding-y-small $btn-padding-x-small;
	}
}

.btn--large {
	font-size: $font-size-base;
	line-height: $btn-line-height-default;
	min-height: $btn-total-height-default;
	padding: $btn-padding-y-default $btn-padding-x-default;

	@media (min-width: $break-point-d) {
		font-size: 18px;
		line-height: $btn-line-height-large;
		min-height: $btn-total-height-large;
		padding: $btn-padding-y-large $btn-padding-x-large;
	}
}

.btn--style-a {
	background-color: $brand-color-a;
	border-color: $brand-color-a;
	color: #000;

	&:hover,
	&:focus {
		background: darken($brand-color-a, 10%);
		border-color: darken($brand-color-a, 10%);
	}
}

.btn--style-b {
	background-color: $brand-color-b;
	border-color: $brand-color-b;
	color: #FFF;

	&:hover,
	&:focus {
		background: darken($brand-color-b, 10%);
		border-color: darken($brand-color-b, 10%);
	}
}

.btn--style-c {
	background-color: $brand-color-c;
	border-color: $brand-color-c;
	color: #FFF;

	&:hover,
	&:focus {
		background: darken($brand-color-c, 10%);
		border-color: darken($brand-color-c, 10%);
	}
}

.btn--style-d {
	background-color: $brand-color-d;
	border-color: $brand-color-d;
	color: #FFF;

	&:hover,
	&:focus {
		background: lighten($brand-color-d, 10%);
		border-color: lighten($brand-color-d, 10%);
	}
}
