// utilities.scss

.invisible {
	visibility: hidden;
}

.clearfix {
	zoom: 1;

	&:before,
	&:after {
		content: '';
		display: table;
	}

	&:after {
		clear: both
	}
}

.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

	&.focusable:active,
	&.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}

.no-transition,
.no-transition * {
	transition: none!important;
}

// positioning
.vertically-centered {
	.csstransforms & {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

.horizontally-centered {
	.csstransforms & {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
}

.totally-centered {
	.csstransforms & {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
