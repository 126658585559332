// Type

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;

	a {
		text-decoration: none;
	}
}

h1,
.giga,
.title--style-a {
	font-size: 60px;
	line-height: $vr*8;
	margin-bottom: $vr*4;
}

h2,
.mega,
.title--style-b {
	font-size: 25px;
	line-height: $vr*5;
	margin-bottom: $vr*2;

	@media #{$BPD} {
		font-size: 35px;
		line-height: $vr*7;
		margin-bottom: $vr*3;
	}	

	@media #{$BPF} {
		font-size: 40px;
		line-height: $vr*8;
		margin-bottom: $vr*4;
	}
}

h3,
.alpha,
.title--style-c {
	font-weight: $font-weight-regular;
	font-size: 16px;
	line-height: $vr*4;
	margin-bottom: $vr;

	@media #{$BPD} {
		font-size: 22px;
		line-height: $vr*5;
		margin-bottom: $vr*2;
	}

	@media #{$BPF} {
		font-size: 26px;
		line-height: $vr*6;
		margin-bottom: $vr*2;
	}
}

h4,
.beta,
.title--style-d {
	font-size: 18px;
	line-height: $vr*3;
	margin-bottom: $vr*2;
}

h5,
.gamma,
.title--style-e {
	font-size: $font-size-base;
	line-height: $vr*3;
	margin-bottom: $vr*2;
}

h6,
.delta,
.title--style-f {}

// Paragraphs
p {
	margin-bottom: $vr*2;
}

small {
	font-size: $font-size-small;
}

// only used on the stylesheet
.header-stylesheet {
	border-bottom: 1px solid #CCC;
	color: #bbb;
	font-size: 18px;
	line-height: $vr*4;
	margin-bottom: $vr*4;
	padding-top: $vr*6;
	padding-bottom: $vr*2;
	text-align: left;

	&:first-child {
		padding-top: 0;
	}
}
