// Lists

.list-unstyled {
	list-style: none;
	margin: 0;
	padding: 0;
}

.list-unstyled--full {
	list-style: none;
	margin: 0;
	padding: 0;

	ol,
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}
