// Icons

@font-face {
    font-family: 'Museo 300';
    src: url('#{$directory-fonts}/Museo300Regular.eot');
    src: url('#{$directory-fonts}/Museo300Regular.eot') format('embedded-opentype'),
         url('#{$directory-fonts}/Museo300Regular.woff2') format('woff2'),
         url('#{$directory-fonts}/Museo300Regular.woff') format('woff'),
         url('#{$directory-fonts}/Museo300Regular.ttf') format('truetype'),
         url('#{$directory-fonts}/Museo300Regular.svg#Museo300Regular') format('svg');
}

@font-face {
    font-family: 'Museo 500';
    src: url('#{$directory-fonts}/Museo500Regular.eot');
    src: url('#{$directory-fonts}/Museo500Regular.eot') format('embedded-opentype'),
         url('#{$directory-fonts}/Museo500Regular.woff2') format('woff2'),
         url('#{$directory-fonts}/Museo500Regular.woff') format('woff'),
         url('#{$directory-fonts}/Museo500Regular.ttf') format('truetype'),
         url('#{$directory-fonts}/Museo500Regular.svg#Museo500Regular') format('svg');
}

@font-face {
    font-family: 'Museo 700';
    src: url('#{$directory-fonts}/Museo700Regular.eot');
    src: url('#{$directory-fonts}/Museo700Regular.eot') format('embedded-opentype'),
         url('#{$directory-fonts}/Museo700Regular.woff2') format('woff2'),
         url('#{$directory-fonts}/Museo700Regular.woff') format('woff'),
         url('#{$directory-fonts}/Museo700Regular.ttf') format('truetype'),
         url('#{$directory-fonts}/Museo700Regular.svg#Museo700Regular') format('svg');
}


@font-face {
	font-family: '#{$icon-font}';
	src:url('#{$directory-fonts}/#{$icon-font}.eot?-gfwsty');
	src:url('#{$directory-fonts}/#{$icon-font}.eot?#iefix-gfwsty') format('embedded-opentype'),
	url('#{$directory-fonts}/#{$icon-font}.ttf?-gfwsty') format('truetype'),
	url('#{$directory-fonts}/#{$icon-font}.woff?-gfwsty') format('woff'),
	url('#{$directory-fonts}/#{$icon-font}.svg?-gfwsty##{$icon-font}') format('svg');
	font-weight: normal;
	font-style: normal;
}

%icon {
	font-family: '#{$icon-font}';
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	b {
        @include visually-hidden();
    }
}

.icon{
	@extend %icon;
}

.icon--placeholder:before {
	content: '\f096';
}

.bb-icon {
	font-family: 'bb-icon' !important;
}
