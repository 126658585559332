// sso.scss

.sso-panel {
	background-color: $brand-white;
	padding: $vr * 2;
}

.sso-panel__inner {
	@media #{$BPD} {
		padding: $vr * 4;
	}
	@media #{$BPF} {
		padding: $vr * 4 $vr * 22;
	}
}

.sso-panel__header {
	text-align: center;
	margin-bottom: $vr * 3;
	color: #3d1152;

	@media #{$BPD} {
		margin-bottom: $vr * 5;
	}
}

.sso-panel__icon {
	display: block;
	margin: 0 auto $vr * 4;

	img {
		height: 48px;

		@media #{$BPC} {
			height: 60px;
		}

		@media #{$BPD} {
			height: auto;
		}
	}
}

.sso-link,
.remember-me-link,
.contact-us-link {
	font-size: $font-size-small;
	font-weight: $font-weight-bold;
	color: $sso-dark-purple;
	text-decoration: underline;

	&:hover {
		color: $sso-dark-blue;
		text-decoration: underline;
	}

	&:visited {
		color: $sso-dark-purple;
		text-decoration: underline;
	}
}

.sso-form-links {
	@extend .list-unstyled--full;
	text-align: center;

	@media #{$BPD} {
		text-align: left;
	}
}

.sso-form-submit {
	display: block;
	margin: $vr * 3 auto;
	width: 100%;
	background-color: $sso-bright-yellow;
	color: $sso-dark-purple;
	border: none;
	border-radius: 10px;
	background: linear-gradient(to right, #3d1152 50%, #ffb701 50%);
	background-size: 200% 100%;
	background-position: right;
	transition: all 0.35s ease-out;
	box-shadow: none;
	cursor: pointer;
	font-family: "Museo 300", "Open Sans", Helvetica, Arial, sans-serif;
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 1.25rem;
	min-height: 2rem;
	padding: 1.125rem 1.5625rem;
	text-align: center;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $brand-white;
		background-position: left;
	}

	@media #{$BPD} {
		float: right;
	}
}

.loader {
	font-size: 10px;
	margin: 5px auto;
	text-indent: -9999em;
	width: 7em;
	height: 7em;
	border-radius: 50%;
	background: $loader-yellow;
	background: -moz-linear-gradient(
		left,
		$loader-yellow 10%,
		rgba(114, 191, 68, 0) 42%
	);
	background: -webkit-linear-gradient(
		left,
		$loader-yellow 10%,
		rgba(114, 191, 68, 0) 42%
	);
	background: -o-linear-gradient(
		left,
		$loader-yellow 10%,
		rgba(114, 191, 68, 0) 42%
	);
	background: -ms-linear-gradient(
		left,
		$loader-yellow 10%,
		rgba(114, 191, 68, 0) 42%
	);
	background: linear-gradient(to right, $loader-yellow 10%, #fff 42%);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.loader:before {
	width: 50%;
	height: 50%;
	background: $loader-yellow;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
}
.loader:after {
	background: $brand-white;
	width: 90%;
	height: 90%;
	border-radius: 50%;
	content: "";
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
@-webkit-keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load3 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
