// global.scss

* {
	-webkit-tap-highlight-color: transparent;
	word-wrap: break-word;
	box-sizing: border-box;
}

:focus {
	outline: none;
}

@include placeholder(#999);

html {
	font-size: 100%;
	background: #fff;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// Disable iOS/WinMobile font size changes
	@media screen and (max-device-width: 480px) {
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
	}
}

body {
	color: $color-text;
	font-family: $font-family-base;
	font-weight: $font-weight-regular;
	line-height: $line-height-base;
}

.fixed-bg {
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	min-height :100vh;

	background-image: url(/images/LoginScreenBackground-mobile.jpeg);
	@media (min-width: $break-point-d) {
		background-image: url(/images/LoginScreenBackground-tablet.jpeg);
	}
	@media (min-width: $break-point-f) {
		background-image: url(/images/LoginScreenBackground-desktop.jpeg);
	}
}

img {
	-ms-interpolation-mode: bicubic;
}

a {
	color: $color-link;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
	&:visited {
		color: $color-link;
	}
}

.inline-icon-link {
	font-size: 18px;
	color: #3D1152;

	.icons:before {
		vertical-align: middle;
		font-size: 18px;
		line-height: 1;
	}

	span {
		font-weight: $font-weight-medium;
		text-decoration: underline;
	}

	&:hover {
		color: $sso-dark-blue;

		span {
			text-decoration: underline;
		}
	}
}

.grid {
	display: none;

	.layout {
		padding: 0;
	}

	.visible-grid-in & {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1001;
		background: rgba(0,0,0,0);
		display: block;

		body:hover & {
			z-index: 0;
		}

		.layout,
		.region,
		.region-inner,
		.block {
			height: 100%;
		}

		.block {
			background: red;
			opacity: 0.1;
		}
	}
}


.visible-grid-btn {
	display: block;
	position: fixed;
	bottom: $vr*3;
	left: $vr*3;
	z-index: 1002;

	.demo-actions & {
		position: relative;
		bottom: auto;
		left: auto;
		float: left;
		margin-right: 5px;
	}
}
