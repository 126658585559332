// http://github.com/necolas/normalize.css | https://github.com/shkm/normalize.sass
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

body,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

a {
  color: #00e;

  &:visited {
    color: #551a8b;
  }

  &:focus {
    outline: thin dotted;
  }

  &:hover,
  &:active {
    outline: 0;
  }
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;

  &:before,
  &:after {
    content: '';
    content: none;
  }
}

small {
  font-size: 75%;
}

blockquote {
  margin: 1em 40px;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  margin: 1em 0;
  padding: 0 0 0 40px;
}

dd {
  margin: 0 0 0 40px;
}

nav {
  ul,
  ol {
    list-style: none;
    list-style-image: none;
  }
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  *margin-left: -7px;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

table {
  button,
  input {
    *overflow: auto;
  }
}

button,
html input[type="button"],
html input[type="reset"],
html input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input {

  &[type="checkbox"],
  &[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  &[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-spacing: 0;
}
