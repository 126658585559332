// utilities-responsive.scss

.visible-smallscreen,
.visible-mediumscreen,
.visible-largescreen {
	display: none;
}

.visible-smallscreen {
	display: block;
}

.visible-mediumscreen {
	@media (min-width: $break-point-d) {
    	display: block;
  	}
}

.visible-largescreen {

	@media (min-width: $break-point-e) {
		display: block;
  	}
}

.hidden-mediumscreen {
	@media (min-width: $break-point-d) {
    	display: none;
  	}
}

.hidden-largescreen {
	@media (min-width: $break-point-e) {
    	display: none;
  	}
}
